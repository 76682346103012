import Keycloak from 'keycloak-js';
import Vue from 'vue';

let initOptions = {
    url: process.env.VUE_APP_AUTH_URL,
    realm: process.env.VUE_APP_AUTH_REALM,
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID
};

const keycloak = Keycloak(initOptions);

Vue.prototype.$keycloak = keycloak;

export default keycloak;
