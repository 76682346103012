import { getProperty, setProperty } from 'dot-prop';

export default function getMissingPropertiesFromConfiguration(configuration, specFeatures) {
    let missingProperties = {}
    Object.keys(specFeatures).forEach(featureName => {
      let configFeature = configuration[featureName]
      // If feature is in config
      if(configFeature) {
        // If feature has property groups
        let propertyGroups = specFeatures[featureName].property_groups
        if(propertyGroups) {
          Object.keys(propertyGroups).forEach(propertyGroupName => {
            let byteGroups = propertyGroups[propertyGroupName].byte_groups
            byteGroups.forEach(byteGroup => {
              let specProperties = byteGroup.properties
              Object.keys(specProperties).forEach(propertyName => {
                // If property is in spec but not in config, replace with default value
                if(getProperty(configFeature, `${propertyGroupName}.${propertyName}`) == undefined) {
                  // console.log(`"${propertyName}" in "${featureName} --> ${propertyGroupName}" is missing`)
                  let defaultPropertyValue = specProperties[propertyName].default
                  setProperty(missingProperties, `${featureName}.${propertyGroupName}.${propertyName}`, defaultPropertyValue)
                  setProperty(configuration, `${featureName}.${propertyGroupName}.${propertyName}`, defaultPropertyValue)
                }
              })
            })
          }) 
        }
        // If feature does not have property groups
        else {
          let byteGroups = specFeatures[featureName].byte_groups
          byteGroups.forEach(byteGroup => {
            let specProperties = byteGroup.properties
            Object.keys(specProperties).forEach(propertyName => {
              // If property is in spec but not in config, replace with default value
              if(configFeature[propertyName] == undefined) {
                // console.log(`"${propertyName}" in "${featureName}" is missing`)
                let defaultPropertyValue = specProperties[propertyName].default
                setProperty(missingProperties, `${featureName}.${propertyName}`, defaultPropertyValue)
                setProperty(configuration, `${featureName}.${propertyName}`, defaultPropertyValue)
              }
            })
          })
        }
      }
    })
    return missingProperties
}