<template>
    <div>
        <span>{{ $t('DifficultySelector.title') }}</span>
        <DifficultySelector /> 
        <VersionSelector 
            class="mt-4"
        />
    </div>
</template>   

<script>
import DifficultySelector from '@/components/DifficultySelector.vue'
import VersionSelector from '@/components/VersionSelector.vue'

export default {
    components: {
        DifficultySelector,
        VersionSelector
    }
}
</script>