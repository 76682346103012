<template>
    <div>
        <Dialog 
            v-model="showInfo"
            :title="$t('Info.info')"
            width="40%"
        >
            {{ text }}
            <div v-if="href">
                <br>
                <i18n path="Info.link">
                    <a :href="href" target="_blank">{{ $t('Info.here') }}</a>
                </i18n>
            </div>
        </Dialog>
        <v-icon 
            @click="showInfo = true"
            class="ml-2"            
        >
            mdi-information-outline
        </v-icon>
    </div>
</template>

<script>
export default {
    data: () => ({
        showInfo: false,
    }),
    props: {
        text: {
            type: String,
            required: true
        },
        href: String
    },
    components: {
        Dialog: () => import('@/components/dialogs/Dialog.vue')
    }
}
</script>