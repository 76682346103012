<template>
    <v-menu
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon 
                v-bind="attrs"
                v-on="on"
                :disabled="loading && !isLoggedIn"
            >
                mdi-account
            </v-icon>
        </template>
        <v-list>
            <div v-if="isLoggedIn">
                <v-list-item>
                    Hi, {{username}}
                </v-list-item>
            <v-divider/>
            </div>
            <v-list-item-group>
                <v-list-item v-if="isLoggedIn" @click="logout">
                    <v-list-item-title>{{ $t('AccountButton.logout') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="login">
                    <v-list-item-title>{{ $t('AccountButton.login') }}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    computed: {
        loading() {
            return this.$store.getters.loadingEditor
        },
        username() {
            return this.$store.getters.username
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
        },
        login() {
            const loginUrl = this.$keycloak.createLoginUrl()
            window.location.replace(loginUrl)
        }
    }
}
</script>