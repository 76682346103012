<template>
    <v-select
        v-model="versions"
        :label="$t('VersionSelector.label')"
        :items="selectableReleases"
        item-text="versions.st"
        item-value="versions"
        filled
        hide-details="auto"
        @change="deselectFeatureCheck"
        :disabled="disabled"
    >
        <template v-slot:append-outer v-if="!hideInfo">
            <v-icon 
                @click="openReleasesUrl"
                class="mt-4"
            >
                mdi-information-outline
            </v-icon>
        </template>
        <template v-slot:append>
            <v-icon>{{ disabled ? "" : "mdi-chevron-down" }}</v-icon>
        </template>
        <template v-slot:item="item">
            <h5>
                <b>{{ `ST: ${item.item.versions.st}` }}</b>  
                {{ `&nbsp;NRF: ${item.item.versions.nrf} ESP: ${item.item.versions.esp}`}}
            </h5>
            <h6> {{ `&nbsp;&nbsp;&nbsp;${item.item.label}` }} </h6>
        </template>
    </v-select>
</template>

<script>
import { cloneDeep } from 'lodash'
import stMinVersionMet from '../utils/stMinVersionMet'
export default {
    props: {
        hideInfo: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        versions: {
            get() {
                return this.$store.getters.versions
            },
            set(value) {
                this.$store.dispatch('setVersions', value)
            }
        },
        releases() {
            return this.$store.getters.releases
        },
        selectableReleases() {
            let selectableReleases = cloneDeep(this.releases) || []
            if(!this.isAdmin) {
                selectableReleases = selectableReleases.filter(release => !release.adminOnly)
            }
            if(this.templateMinVersion) {
                selectableReleases = selectableReleases.filter(release => stMinVersionMet(release.versions.st, this.templateMinVersion.st))
            }
            return selectableReleases
        },
        isAdmin() {
            return this.$store.getters.isAdmin
        },
        templateMinVersion() {
            return this.$store.getters.template?.min_version
        }
    },
    methods: {
        openReleasesUrl() {
            window.open('https://docs.iotracker.eu/release_notes/iot3/#releases', '_blank')
        },
        deselectFeatureCheck() {
            let selected = this.$store.getters.selected
            if(selected) {
                // TODO: deselect selected feature if not within min/max versions
            }
        }
    },
    watch: {
        templateMinVersion(versions) {
            if(versions) {
                this.versions = this.selectableReleases[0].versions
            }
        }
    }
}
</script>