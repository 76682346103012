import Vue from 'vue'
import { getProperty, setProperty } from 'dot-prop';
import { clone, cloneDeep, forOwn, isEqual, remove, some } from 'lodash'
import allPropsAreNull from '@/utils/allPropsAreNull.js'
import translate from '@/utils/translate';
import setAllPropsToNull from '@/utils/setAllPropsToNull';
import i18n from '@/i18n.js' 
// import DownlinkGenerator from '@/utils/DownlinkGenerator';
import createDefaultConfig from '@/utils/createDefaultConfig';
import fillDesired from '@/utils/fillDesired';
import router from '@/router/index.js'
import apiClient from "@/api/apiClient";
import getMissingPropertiesFromConfiguration from '@/utils/getMissingPropertiesFromConfiguration.js';


const state = {
    loadingEditor: true,
    versions: null,
    releases: [],
    selected: null,
    spec: null,
    difficulty: 3,
    configList: null, 
    name: {
      desired: null,
      actual: null
    },
    config: {
      desired: null,
      actual: null,
      default: null,
      template: null
    },
    defaultConfigs: [],
    invalidProperties: [],
    downlinks: null,
    showDownlinks: false,
    savingIsDisabled: false,
    missingProperties: null,
}
const mutations = {
    SET_SPEC(state, spec) {
      setProperty(state, 'spec', spec)
      // Set default config
      let defaultConfig = createDefaultConfig(spec.features)
      setProperty(state.config, 'default', defaultConfig)
    },
    SET_DIFFICULTY(state, difficulty) {
      setProperty(state, 'difficulty', difficulty)
    },
    SET_NAME(state, name) {
      setProperty(state.name, 'desired' , name)
    },
    RESTORE_NAME(state) {
      let actual = getProperty(state.name, 'actual')
      setProperty(state.name, 'desired', actual)
    },
    SET_SELECTED(state, selected) {      
      if(isEqual(getProperty(state, 'selected'), selected)) {
        setProperty(state, 'selected', null)
      }
      else {
        setProperty(state, 'selected', selected)
        window.scrollTo(0,0)
      }
    },
    SET_PROPERTY(state, {featureName, propertyGroupName, propertyName, newValue}) {
      let origin = propertyGroupName ? `${featureName}.${propertyGroupName}.${propertyName}` : `${featureName}.${propertyName}`
      let actual = getProperty(state, `config.actual.${origin}`)
      if(newValue != actual) {
        setProperty(state, `config.desired.${origin}`, newValue)
        return
      }
      setProperty(setProperty(state, `config.desired.${origin}`, null))
    },
    RESTORE_PROPERTY(state, {featureName, propertyGroupName, propertyName}) {
      let origin = propertyGroupName ? `${featureName}.${propertyGroupName}.${propertyName}` : `${featureName}.${propertyName}`
      setProperty(state, `config.desired.${origin}`, null)
    },
    RESTORE_ALL_PROPERTIES(state, {featureName, propertyGroupName}) {
      let origin = propertyGroupName ? `${featureName}.${propertyGroupName}` : `${featureName}`
      Object.keys(getProperty(state, `config.desired.${origin}`)).forEach(property => {
        setProperty(state, `config.desired.${origin}.${property}`, null)
      })
    },
    RESTORE_ALL_PROPERTIES_TO_DEFAULT(state, featureName) {
      state, featureName
      // console.log(state.spec.features[featureName])
      // createDefaultConfig(features)
      // console.log(featureName)
    },
    SET_PROPERTY_VALID(state, {featureName, propertyGroupName, propertyName}) {
      // Have to use a copy and Vue.set, else reactivity is lost
      var copy = clone(state.invalidProperties)
      remove(copy, {featureName, propertyGroupName, propertyName})
      Vue.set(state, 'invalidProperties', copy)
    },
    SET_PROPERTY_INVALID(state, {featureName, propertyGroupName, propertyName}) {
      if(!some(state.invalidProperties, {featureName, propertyGroupName, propertyName})) {
        state.invalidProperties.push({featureName, propertyGroupName, propertyName})
      }
    },
    SET_DOWNLINKS(state, downlinks) {
      setProperty(state, 'downlinks', downlinks)
    },
    ADD_DEFAULT_CONFIG(state, defaultConfig) {
      state.defaultConfigs.push(defaultConfig)
    },
    FETCH_CONFIG(state) {
      setProperty(state, 'loadingEditor', true)
      // TODO move API interactions (mutations -> actions)

      // If in template route --> fetch template
      let currentRoute = router.history.current

      if(router.history.current.name == 'template') {
        let templateId = currentRoute.params.template_id
        let configuration
        apiClient.get(`/device-configuration-templates/${templateId}`)
          .then(response => {
            // Store config if found and set the name of the config
            configuration = response.data.configuration
            let name = response.data.name
            setProperty(state, 'savingIsDisabled', false)
            setProperty(state, 'name.actual', name)
            setProperty(state, 'name.desired', name)

            // Set all missing properties for notification
            let specFeatures = state.spec.features
            state.missingProperties = getMissingPropertiesFromConfiguration(configuration, specFeatures)

          })
          .catch(() => {
            // If not found, create config file based on default values
            setProperty(state, 'savingIsDisabled', true)
            configuration = createDefaultConfig(state.spec.features)
          })
          .finally(() => {
            Vue.set(state.config, 'actual', configuration)
            let nullCopy = setAllPropsToNull(clone(configuration))
            Vue.set(state.config, 'desired', nullCopy)            
            setProperty(state, 'loadingEditor', false)
          })
      }
      // If in base route --> generate default template
      else {
        setProperty(state, 'savingIsDisabled', true)
        let configuration = createDefaultConfig(state.spec.features)
        Vue.set(state.config, 'actual', configuration)
        let nullCopy = setAllPropsToNull(clone(configuration))
        Vue.set(state.config, 'desired', nullCopy)// TODO: fetch template
        
        setProperty(state, 'loadingEditor', false)
      }
      
    },
    SAVE_CHANGES(state) {
      let filledDesired = fillDesired(state.config.actual, state.config.desired)
      // TODO move API interactions (mutations -> actions)
      let templateId = router.currentRoute.params.template_id
      let body = {
        name: getProperty(state.name, 'desired'),
        configuration: filledDesired
      }
      let headers = {
        'Content-Type': 'application/merge-patch+json'
      }
      apiClient.patch(`/device-configuration-templates/${templateId}`, body, {headers})
        .then(() => {
          setProperty(state.name, 'actual', getProperty(state.name, 'desired'))
          setProperty(state.config, 'actual', filledDesired)
          let nullCopy = setAllPropsToNull(clone(state.config.actual))
          Vue.set(state.config, 'desired', nullCopy)
        })
        .catch((err) => {
          // TODO snackbar if save failed
          console.error(err)
        })
    },
    CLEAR_TEMPLATES(state) {
      setProperty(state.config, 'actual', null)
      setProperty(state.config, 'desired', null)
    },
    SHOW_DOWNLINKS(state, show) {
      setProperty(state, 'showDownlinks', show)
    },
    SET_VERSIONS(state, versions) {
      setProperty(state, 'versions', versions)
    },
    SET_FEATURE_TO_DEFAULT(state, {featureName, featureDefaultConfig}) {
      Vue.set(state.config.desired, featureName, featureDefaultConfig)
    },
    ADD_MISSING_FEATURE_TO_CONFIG(state, {featureName, featureDefaultConfig}) {
      Vue.set(state.config.actual, featureName, featureDefaultConfig)
      let nullCopy = setAllPropsToNull(clone(featureDefaultConfig))
      Vue.set(state.config.desired, featureName, nullCopy)
    },
    SET_FEATURE(state, { featureName, newValue }) {
      setProperty(state.config.desired, featureName, newValue)
    },
    SET_ACTUAL(state, configuration) {
      Vue.set(state.config, 'actual', configuration)
    },
    SET_DESIRED(state, configuration) {
      Vue.set(state.config, 'desired', configuration)
    },
    SET_DEFAULT(state, configuration) {
      Vue.set(state.config, 'default', configuration)
    },
    SET_MISSING_PROPERTIES(state, missingProperties) {
      Vue.set(state, 'missingProperties', missingProperties)
    }
}
const actions = {
    // TODO don't modify state in actions
    async initializeEditor({commit}){
      setProperty(state, 'selected', null)
      setProperty(state, 'loadingEditor', true)
      // Not using the apiclient since unauthenticated users should also be able to access the spec
      // TODO: remove duplicate spec fetch call (should only be done during general initialization)
      return Vue.axios
        .get(process.env.VUE_APP_SPEC_API_URL)
        .then(response => {
          commit('SET_SPEC', response.data)
          commit('SET_VERSIONS', response.data.releases[0].versions)
          // Small delay to prevent routing issues in Firefox and Safari
          setTimeout(() => {
            commit("FETCH_CONFIG")  
          }, 100)
          
          // TODO: fetch template (then, catch, finally)
          
        })
        .catch(err => {
          console.log(err)
        })
    },
    async fetchSpec({commit, dispatch}) {
      // Not using the apiclient since unauthenticated users should also be able to access the spec
      return Vue.axios
        .get(process.env.VUE_APP_SPEC_API_URL)
        .then(response => {
          commit('SET_SPEC', response.data)
          dispatch('setTemplates', response.data.templates)
          // dispatch('setTemplates', response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    async fetchDefaultConfigs({commit}) {
      // Fetch ioTracker 3 default config
      Vue.axios.get(process.env.VUE_APP_SPEC_API_URL + "/default-configuration/iotracker-3")
        .then(response => {
          commit('ADD_DEFAULT_CONFIG', {
              name: "ioTracker 3 Default", 
              config: response.data
            })
        })
        .catch(err => {
          console.log(err)
        })
      // Fetch ioTracker Pro default config
      // Vue.axios.get(process.env.VUE_APP_SPEC_API_URL + "/default-configuration/iotracker-pro")
      //   .then(response => {
      //     commit('ADD_DEFAULT_CONFIG', {
      //         name: "ioTracker Pro Default", 
      //         config: response.data
      //       })
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      // Fetch ioTracker Pullcord default config
      // Vue.axios.get(process.env.VUE_APP_SPEC_API_URL + "/default-configuration/iotracker-pullcord")
      //   .then(response => {
      //     commit('ADD_DEFAULT_CONFIG', {
      //         name: "ioTracker Pullcord Default", 
      //         config: response.data
      //       })
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      return 
    },
    async generateDownlinks({commit, state}, {selectedFeatureNames, enableGpio, enableGps, enableOnboardSensors}) {
      commit('SET_DOWNLINKS', null)
      let downlinkData = new Object()
      let actual = cloneDeep(state.config.actual)
      let desired = cloneDeep(state.config.desired)
      // Copy all features from the current (actual) config that are selected to the downlink request body
      Object.keys(actual).forEach(featureName => {
        if(selectedFeatureNames.includes(featureName)) {
          downlinkData[featureName] = actual[featureName]
        }
      }) 
      // Replace the values of the request body where the desired config values != null
      Object.keys(downlinkData).forEach(featureName => {
        let feature = desired[featureName]
        Object.keys(feature).forEach(key => {
          let value = feature[key]
          if(value != null && typeof value == 'object') {
            Object.keys(value).forEach(propName => {
              let propValue = value[propName]
              if(propValue !== null) {
                downlinkData[featureName][key][propName] = propValue
              }
            })
          }
          else {
            if(value !== null) {
              downlinkData[featureName][key] = value
            }
          }
        }) 
      })

      // Generate downlinks using downlink generator API, 
      return Vue.axios.post(process.env.VUE_APP_DOWNLINK_API_URL, downlinkData, {
        params: {
          enableOnboardSensors,
          enableGps,
          enableGpio,
          stVersion: formatVersionToHex(state.versions.st)
        }
      }).then(res => {
        let downlinks = res.data.downlinks
        commit('SET_DOWNLINKS', downlinks)
      })

      function formatVersionToHex(versionString) {
        let versionStringSplit = versionString.split(".").map(Number)
        let buffer = Buffer.alloc(4);
        buffer.writeUInt8(versionStringSplit[0], 0)
        buffer.writeUInt8(versionStringSplit[1], 1)
        buffer.writeUInt16BE(versionStringSplit[2], 2)
        return buffer.toString("hex");

      }

      // // Generate downlink using local copy of downlink generator (FOR TESTING PURPOSES ONLY!)
      // const generator = new DownlinkGenerator(state.spec)
      // return generator.generate(downlinkData, null, enableGpio, enableGps, enableOnboardSensors)
      //   .then(downlinks => {
      //     console.log({downlinkData, enableGpio, enableGps, enableOnboardSensors})
      //     commit('SET_DOWNLINKS', downlinks)
      //   })
    },
    setDifficulty({commit}, difficulty) {
      commit("SET_DIFFICULTY", difficulty)
    },
    setName({commit}, name) {
      commit("SET_NAME", name)
    },
    restoreName({commit}) {
      commit("RESTORE_NAME")
    },
    setSelected({commit}, selected) {
      commit("SET_SELECTED", selected)
    },
    setProperty({commit}, {featureName, propertyGroupName, propertyName, newValue}) {
      commit("SET_PROPERTY", {featureName, propertyGroupName, propertyName, newValue})
    },
    restoreProperty({commit}, {featureName, propertyGroupName, propertyName}) {
      commit("RESTORE_PROPERTY", {featureName, propertyGroupName, propertyName})
    },
    restoreAllProperties({commit}, {featureName, propertyGroupName}) {
      commit("RESTORE_ALL_PROPERTIES", {featureName, propertyGroupName})
    },
    setPropertyValid({commit}, {featureName, propertyGroupName, propertyName}) {
      commit("SET_PROPERTY_VALID", {featureName, propertyGroupName, propertyName})
    },
    setPropertyInvalid({commit}, {featureName, propertyGroupName, propertyName}) {
      commit("SET_PROPERTY_INVALID", {featureName, propertyGroupName, propertyName})
    },
    saveChanges({commit}) {
      commit("SAVE_CHANGES")
    },
    showDownlinks({commit}, show) {
      commit("SHOW_DOWNLINKS", show)
    },
    clearTemplates({commit}) {
      commit("CLEAR_TEMPLATES")
    },
    setVersions({commit}, versions) {
      commit("SET_VERSIONS", versions)
    },
    setTemplateInSetup({commit}, configuration) {
      commit("SET_ACTUAL", cloneDeep(configuration))
      commit("SET_DESIRED", setAllPropsToNull(cloneDeep(configuration)))
      commit("SET_DEFAULT", cloneDeep(configuration))
    },
    setFeatureToDefault({commit, state}, featureName) {
      let featureDefaultConfig = cloneDeep(state.config.default[featureName])
      commit("SET_FEATURE_TO_DEFAULT", {featureName, featureDefaultConfig})
      // dispatch('cleanDesired', featureName)
    },
    addMissingFeatureToConfig({commit, state}, featureName) {
      let featureDefaultConfig = cloneDeep(state.config.default[featureName])
      commit("ADD_MISSING_FEATURE_TO_CONFIG", {featureName, featureDefaultConfig})
    },
    cleanDesired({commit, state}, featureName) {
      let cleanedDesired = {}
      let featureActual = state.config.actual[featureName]
      if(!featureActual) {
        return
      }
      let featureDesired = state.config.desired[featureName]
      Object.keys(featureDesired).forEach(key => {
        if(typeof featureDesired[key] == 'object') {
          let propertyGroupName = key
          Object.keys(featureDesired[propertyGroupName]).forEach(propertyName => {
            let actualValue = getProperty(featureActual, `${propertyGroupName}.${propertyName}`)
            let desiredValue = getProperty(featureDesired, `${propertyGroupName}.${propertyName}`)
            setProperty(cleanedDesired, `${propertyGroupName}.${propertyName}`, actualValue == desiredValue ? null : desiredValue)
          })
        }
        else {
          let propertyName = key
          let actualValue = featureActual[propertyName] 
          let desiredValue = featureDesired[propertyName]
          setProperty(cleanedDesired, propertyName, actualValue == desiredValue ? null : desiredValue)
        }
      })
      commit("SET_FEATURE", { featureName, newValue: cleanedDesired})
    },
    setDesired({commit}, configuration) {
      commit("SET_DESIRED", configuration)
    },
    setMissingProperties({commit}, missingProperties) {
      commit("SET_MISSING_PROPERTIES", missingProperties)
    }
}
const getters = {
    section: (state) => (section) => {
      return getProperty(state, `spec.${section}`)
    },
    spec: (state) => {
      return getProperty(state, 'spec')
    },
    difficulty: (state) => {
      return getProperty(state, `difficulty`)
    },
    name: (state) => {
      return getProperty(state, `name`)
    },
    config: (state) => {
      return getProperty(state, 'config')
    },
    configList: (state) => {
      return getProperty(state, 'configList.configurations')
    },
    defaultConfigs: (state) => {
      return getProperty(state, 'defaultConfigs')
    },
    categories: (state) => {
      return getProperty(state, `spec.categories`)
    },
    features: (state) => {
      return getProperty(state, `spec.features`)
    },
    featureNamesInDesired: (state) => {
      return Object.keys(state.config.desired)
    },
    hardware: (state) => {
      return getProperty(state, `spec.hardware`)
    },
    versions: (state) => {
      return getProperty(state, 'versions')
    },
    selected: (state) => {
      return getProperty(state, 'selected')
    },
    loadingEditor: (state) => {
      return getProperty(state, 'loadingEditor')
    },
    propertySpec: (state) => (featureName, propertyGroupName, propertyName) => {
      let propertySpec = null
      let spec = state.spec.features
      let featureSpec = getProperty(spec, featureName)
      if(propertyGroupName){
        let propertyGroup = getProperty(featureSpec.property_groups, propertyGroupName)
        let byteGroup = propertyGroup.byte_groups.find(byteGroup => Object.keys(byteGroup.properties).includes(propertyName))
        propertySpec = getProperty(byteGroup.properties, propertyName)
      }
      else {
        let byteGroup = featureSpec.byte_groups.find(byteGroup => Object.keys(byteGroup.properties).includes(propertyName))
        propertySpec = getProperty(byteGroup.properties, propertyName)
      }
      return propertySpec
    },
    property: (state) => (featureName, propertyGroupName, propertyName) => {
      let origin = propertyGroupName ? `${featureName}.${propertyGroupName}.${propertyName}` : `${featureName}.${propertyName}`
      let desired = getProperty(state, `config.desired.${origin}`)
      if(desired != null) {
        return desired
      }
      let actual = getProperty(state, `config.actual.${origin}`)
      return actual
    },
    changes: (state) => {
      let features = getProperty(state, `spec.features`)
      let config = getProperty(state, `config`)
      let changes = []
      forOwn(features, function(feature, featureName) {
        let propertyGroups = feature.property_groups
        if(propertyGroups) {
          forOwn(propertyGroups, function(propertyGroup, propertyGroupName) {
            propertyGroup.byte_groups.forEach(byteGroup => {
              forOwn(byteGroup.properties, function(property, propertyName) {
                let desired = getProperty(config, `desired.${featureName}.${propertyGroupName}.${propertyName}`)
                if( desired != null) {
                  let featureTitle = translate(feature.title)
                  let propertyGroupTitle = translate(propertyGroup.title)
                  let propertyTitle = translate(property.title)
                  let origin = `${featureTitle} | ${propertyGroupTitle}`
                  let actual = getProperty(config, `actual.${featureName}.${propertyGroupName}.${propertyName}`)
                  desired = formatChange(property, desired)
                  actual = formatChange(property, actual)
                  changes.push({origin, propertyTitle, desired, actual})
                }
              }) 
            })
          })
        }
        else {
          feature.byte_groups.forEach(byteGroup => {
            forOwn(byteGroup.properties, function(property, propertyName) {
              let desired = getProperty(config, `desired.${featureName}.${propertyName}`)
              if( desired != null) {
                let origin = translate(feature.title)
                let propertyTitle = property.title ? translate(property.title) : propertyName
                let actual = getProperty(config, `actual.${featureName}.${propertyName}`)
                desired = formatChange(property, desired)
                actual = formatChange(property, actual)
                changes.push({origin, propertyTitle, desired, actual})
              }
            }) 
          })
        }
      })
      return changes
      function formatChange(property, value) {
        let formattedValue = value
        switch(property.input_type) {
          case "SWITCH": 
            switch(formattedValue) {
              case 0: 
                formattedValue = i18n.t('Changes.on')
                break
              case 1: 
                formattedValue = i18n.t('Changes.off')
                break
              default:
                formattedValue = null
                break
            }
            break
          case "SLIDER":
          case "SELECT":
            formattedValue = property.options[formattedValue]
            if(typeof formattedValue == 'object') { formattedValue = translate(formattedValue)}
            break
        }
        if(property.scaling_factor && formattedValue != null) {
          formattedValue = formattedValue * property.scaling_factor
        }
        if(property.unit && formattedValue != null) {
          formattedValue = formattedValue + property.unit
        }
        
        return formattedValue ?? "-"
      }
    },
    dirty: (state) => (featureName, propertyGroupName, propertyName) => {
      // check for property dirtiness
      if(propertyName != null) {
        let origin = propertyGroupName ? `${featureName}.${propertyGroupName}.${propertyName}` : `${featureName}.${propertyName}`
        let desired = getProperty(state, `config.desired.${origin}`)
        return desired == null ? false : true
      }
      // check for property group dirtiness
      else if(propertyGroupName != null) {
        let desired = getProperty(state, `config.desired.${featureName}.${propertyGroupName}`)
        return !allPropsAreNull(desired)
      }
      // check for feature dirtiness 
      else if(featureName != null) {
        return !allPropsAreNull(getProperty(state, `config.desired.${featureName}`))
      }
      // check for entire config dirtiness
      return !allPropsAreNull(getProperty(state, 'config.desired'))
    },
    valid: (state) => (featureName, propertyGroupName, propertyName) => {
      if(propertyName != null) {
        return !some(getProperty(state.invalidProperties), {featureName, propertyGroupName, propertyName})
      }
      else if(propertyGroupName != null) {
        return !some(getProperty(state.invalidProperties), {featureName, propertyGroupName})
      }
      else if(featureName != null) {
        return !some(getProperty(state.invalidProperties), {featureName})
      }
      else {
        return state.invalidProperties.length == 0
      }
    },
    downlinks: (state) => {
      return getProperty(state, 'downlinks')
    },
    showDownlinks: (state) => {
      return getProperty(state, 'showDownlinks') 
    },
    savingIsDisabled: (state) => {
      return getProperty(state, 'savingIsDisabled')
    },
    missingProperties: (state) => {
      return getProperty(state, 'missingProperties')
    },
    // propertyIsNotInActual: (state)
    allPropertiesAreOnDefault: (state) => (featureName) => {
      let featureActual = getProperty(state.config.actual, featureName)
      let featureDesired = getProperty(state.config.desired, featureName)
      let featureDefault = getProperty(state.config.default, featureName)
      return !Object.keys(featureDefault).some(key => {
        if(typeof featureDefault[key] == 'object') {
          let propertyGroupName = key
          let propertyGroup = featureDefault[propertyGroupName]
          return Object.keys(propertyGroup).some(propertyName => {
            let defaultValue = getProperty(featureDefault, `${propertyGroupName}.${propertyName}`)
            let currentValue = getProperty(featureDesired, `${propertyGroupName}.${propertyName}`) ?? getProperty(featureActual, `${propertyGroupName}.${propertyName}`)
            if(defaultValue != currentValue) {
              return true
            }
          })
        }
        else {
          let propertyName = key
          let defaultValue = featureDefault[propertyName]
          let currentValue = featureDesired[propertyName] ?? featureActual[propertyName]
          if(defaultValue != currentValue) {
            return true
          }
        }
      })
    },
    releases: (state) => {
      return getProperty(state, 'spec.releases')
    }
}

export default {
  state,
  getters,
  actions,
  mutations,
}