<template>
    <v-icon
        @click="darkMode = !darkMode"
        class="mr-2"
    >
        {{darkMode ? 'mdi-weather-night' : 'mdi-white-balance-sunny'}}
    </v-icon>
</template>

<script>
export default {
    computed: {
        darkMode: {
            get() {
                return this.$vuetify.theme.isDark
            },
            set(newValue) {
                this.$vuetify.theme.dark = newValue
            }
        }
    }
}
</script>