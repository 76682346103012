<template>
    <v-radio-group
        v-model="difficulty"
        :row="isMobileSize()"
        hide-details
        class="mt-0"
    >
        <v-radio
            :label="$t('DifficultySelector.difficulty.1')"
            :value=1
        />
        <v-radio
            :label="$t('DifficultySelector.difficulty.2')"
            :value=2
        />
        <v-radio
            :label="$t('DifficultySelector.difficulty.3')"
            :value=3
        />
    </v-radio-group>
</template>

<script>
export default {
    computed: {
        difficulty: {
            get() {
                return this.$store.getters.difficulty
            },
            set(newValue) {
                this.$store.dispatch("setDifficulty", newValue)
            }
        },
    },
}
</script>