<template>
    <v-dialog 
        :width="width" 
        v-model="show"
        :fullscreen="mobile"
        transition="dialog-bottom-transition"
        scrollable
    > 
        <v-card :rounded="mobile ? '0' : true">
            <v-card-title class="header py-4">
                {{ title }}
                <InfoButton
                    v-if="infoText"
                    :text="infoText"
                    :href="infoHref" 
                />
                <v-spacer/>
                <v-btn 
                    @click="show = false"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text :class="hidePadding ? 'pa-0' : 'pa-4'">
                <slot></slot>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import InfoButton from '../inputs/subcomponents/InfoButton.vue'

export default {
    components: { 
        InfoButton 
    },
    props: {
        value: Boolean,
        title: {
            type: String,
            required: true
        },
        width: {
            type: String,
            default: '30%'
        },
        hidePadding: {
            type: Boolean,
            default: false
        },
        infoText: String,
        infoHref: {
            type: String,
            default: null
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set() {
                this.$emit('input', false)
            }
        },
        mobile() {
            return this.$vuetify.breakpoint.smAndDown
        }
    }
}
</script>