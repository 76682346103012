import { forOwn } from "lodash"

// Returns a copy of an object where the value of each (nested) property is set to null
export default function setAllPropsToNull(obj) {
    let newObj = {}
    forOwn(obj, function(value, key) {
        if(typeof value == 'object') {
            newObj[key] = setAllPropsToNull(value)
        }
        else {
            newObj[key] = null
        }
    })
    return newObj
}