<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <NavDrawer/>
    <v-app-bar
      app
      color="navbar"
      dark
      clipped-left
    >
      <v-btn
        @click="prevPage"
        icon
        small
      >
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-img
        alt="ioTracker Logo"
        class="shrink mr-2"
        contain
        src="./assets/logo-iotracker-light.svg"
        transition="scale-transition"
        width="200" 
      />
      <v-spacer/>
      <DarkModeSelector />
      <LanguageSelector v-if="!mobile"/> <!-- TODO: remove v-if when beta tag has been removed (currently the app bar is too full) --> 
      <AccountButton />

      <!-- Non-mobile only -->
      <v-btn
        v-if="!mobile"
        href="https://docs.iotracker.eu/"
        target="_blank"
        text
        class="ml-2"
      >
        <span class="mr-2">{{$t('Header.documentation')}}</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>

      <!-- Mobile only -->
      <v-icon
        v-if="mobile"
        @click="showMobileSettings = true"
        class="mr-n2 ml-1"
      >
        mdi-dots-vertical
      </v-icon>
      <Dialog
        v-model="showMobileSettings"
        :title="$t('Settings')"
      >
        <MobileSettings/>
      </Dialog>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Dialog from '@/components/dialogs/Dialog.vue'
import MobileSettings from '@/components/MobileSettings.vue'
import LanguageSelector from '@/components/LanguageSelector.vue'
import DarkModeSelector from '@/components/DarkModeSelector.vue'
import AccountButton from '@/components/AccountButton.vue'
import NavDrawer from '@/components/NavDrawer.vue'

export default {
  name: 'App',
  components: {
    Dialog,
    MobileSettings,
    LanguageSelector,
    DarkModeSelector,
    AccountButton,
    NavDrawer
  },
  created() {
    document.title = "Downlink Configurator - ioTracker";
    this.$store.dispatch('initialize').then(() => {
      
    })
  },
  data: () => ({
    showMobileSettings: false,
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    }
  },
  methods: {
    prevPage() {
      switch(this.$route.name) {
        default:
        case 'template': 
          this.$router.push('/menu')
          break;
        case 'menu': {
          this.$router.push('/console')
          break;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/iotracker.scss";

</style>
