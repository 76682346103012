import { forOwn, cloneDeep } from "lodash"

export default function fillDesired(actual, desired) {
    let filledDesired = cloneDeep(desired)
    forOwn(actual, function(feature, featureName){
        forOwn(feature, function(property, propertyName) {
            if(typeof property == 'object') {
                // if property is a property group
                let propertyGroup = property
                let propertyGroupName = propertyName
                forOwn(propertyGroup, function(property, propertyName) {
                    if(desired[featureName][propertyGroupName][propertyName] == null) {
                        filledDesired[featureName][propertyGroupName][propertyName] = property
                    }
                })
            }
            else {
                // if property is just a property
                if(desired[featureName][propertyName] == null) {
                    filledDesired[featureName][propertyName] = property
                }
            }
        })
    })
    return filledDesired
}