// Does a deep check to verify that the value of each (nested) property of an object is null
export default function allPropsAreNull(obj) {
    return Object.values(obj).every(value => {
        if(typeof value == 'object' && value != null) {
            return allPropsAreNull(value)
        }
        else {
            if(value == null) {
                return true
            }
            return false
        }
    })
}