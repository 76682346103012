// initial state
import keycloak from "@/plugins/keycloak";
import apiClient from "@/api/apiClient";

let initializePromiseResolve;

const state = {
    userProfile: null,
    apiRoles: [],
    organizations: [],
    activeOrganization: null,
    organizationCount: null,
    isLoggedIn: false,
    initializePromise: new Promise((resolve, reject) => {
        reject
        initializePromiseResolve = resolve
    })
};

// getters
const getters = {
    accountUrl: () => {
        return keycloak.createAccountUrl()
    },
    accessToken: () => {
        return keycloak.token
    },
    username: () => {
        return keycloak.tokenParsed.given_name;
    },
    activeOrganizationId: state => {
        if (state.activeOrganization) {
            return state.activeOrganization.uuid
        } else {
            return null;
        }
    },
    activeOrganization: state => {
        return state.activeOrganization;
    },
    allOrganizations: state => {
        return state.organizations
    },
    /**
     * Returns true if user has admin role on the API
     * @returns {boolean}
     */
    isAdmin: state => {
        return state.apiRoles.indexOf('ROLE_ADMIN') !== -1;
    },
    isLoggedIn: state => {
        return state.isLoggedIn;
    }
};

// // actions
// const actions = {
//     initialize({ commit, dispatch }) {
//         console.log('%cauth %cinitializing', 'color: #fd7e14', 'color: #6c757d');
//         // Uses check-sso to allow users to visit pages that do not require authentication 
//         return keycloak.init({ onLoad: 'check-sso', promiseType: 'native' })
//             .then((authenticated) => {
//                 // Most important user data has been retrieved. Routing can be resumed
//                 initializePromiseResolve()
//                 if (authenticated) {
//                     console.log('%cauth %cauthenticated', 'color: #fd7e14', 'color: #6c757d');

//                     let organizationIds = keycloak.tokenParsed.api_organization_ids || [];
//                     console.log('%cauth %csuccesful, got %c' + organizationIds.length + '%c organizations', 'color: #fd7e14', 'color: #6c757d', 'color: #0abb87', 'color: #6c757d');
//                     commit('SET_ROLES', {apiRoles: keycloak.tokenParsed?.resource_access?.api?.roles || []});

//                     return dispatch('loadUserProfile').then(() => {

                        

//                         return dispatch('loadOrganizations');
//                     });
//                 } else {
//                     // TODO: Handle error
//                 }

//             }).catch(e => {
//                 console.error('Failed to initialize keycloak: ', e);
//             }).finally(() => {
//                 state.isFetchingUser  = false
//             })
//     },
//     loadUserProfile({ commit }) {
//         console.log('%cauth %cloading profile', 'color: #fd7e14', 'color: #6c757d');
//         keycloak.loadUserProfile()
//             .then(profile => {
//                 console.log('%cauth %creceived profile', 'color: #fd7e14', 'color: #6c757d');
//                 commit('SET_USER_PROFILE', profile);
//                 return profile
//             })
//             .catch(e => {
//                 console.error('Failed to load profile: ', e);
//             })
//     },
//     loadOrganizations({ commit, state }) {
//         return apiClient.get('/organizations').then(response => {
//             console.log(keycloak.tokenParsed)
//             let organizations = response.data['hydra:member'];
//             commit('SET_ORGANIZATIONS', organizations);

//             let storedOrganizationId = window.localStorage.getItem('active_organization_id');
//             if (storedOrganizationId && organizations.find(org => org.uuid === storedOrganizationId)) {
//                 commit('SET_ACTIVE_ORGANIZATION', storedOrganizationId);
//             } else if (state.activeOrganizationId === null && organizations.length >= 1) {
//                 commit('SET_ACTIVE_ORGANIZATION', organizations[0].uuid)
//             }
//         });
//     },
//     logout() {
//         let redirectUri = window.location.href;
//         keycloak.logout(redirectUri);
//     }
// };

// actions
const actions = {
    initialize({ dispatch, commit }) {
        console.log('%cauth %cinitializing', 'color: #fd7e14', 'color: #6c757d');
        return keycloak.init({ onLoad: 'check-sso', promiseType: 'native' })
            .then((authenticated) => {
                // Resolve promise to allow routing to continue
                initializePromiseResolve()
                if (authenticated) {
                    console.log('%cauth %cauthenticated', 'color: #fd7e14', 'color: #6c757d');

                    let organizationIds = keycloak.tokenParsed.api_organization_ids || [];
                    console.log('%cauth %csuccesful, got %c' + organizationIds.length + '%c organizations', 'color: #fd7e14', 'color: #6c757d', 'color: #0abb87', 'color: #6c757d');

                    commit('SET_ROLES', { apiRoles: keycloak.tokenParsed?.resource_access?.api?.roles || [] });

                    return dispatch('loadUserProfile').then(() => {



                        return dispatch('loadOrganizations').then(() => {
                            dispatch('fetchSpec')
                            dispatch('fetchDefaultConfigs')
                        });
                    });
                } else {
                    // TODO: Handle error
                }

            }).catch(e => {
                console.error('Failed to initialize keycloak: ', e);
            }).finally(() => {
                // TODO: don't directly affect state in action
                state.isFetchingUser  = false
            });
    },
    loadUserProfile({ commit }) {
        console.log('%cauth %cloading profile', 'color: #fd7e14', 'color: #6c757d');
        keycloak.loadUserProfile()
            .then(profile => {
                console.log('%cauth %creceived profile', 'color: #fd7e14', 'color: #6c757d');
                commit('SET_USER_PROFILE', profile);
                return profile
            })
            .catch(e => {
                console.error('Failed to load profile: ', e);
            })
    },
    /**
     * This action initializes the active organization, and organization count for the active user
     * @returns {Promise<any>}
     */
    loadOrganizations({ dispatch, commit, state }) {

        // First ask the API to which organizations we are allowed
        return apiClient.get('/organizations').then(response => {

            // Store the total number of organization (which may be more than the current page)
            // This is used in the OrganizationSwitcher
            commit('SET_ORGANIZATION_COUNT', response.data['hydra:totalItems'])
            commit('SET_ORGANIZATIONS', response.data['hydra:member']);

            // Check if we already have an active organization
            if (!state.activeOrganization) {

                // Get the the organizations from the array
                let organizations = response.data['hydra:member'];

                // Determine for which organization id we are going to search
                let searchOrganizationId;

                // First, check local storage
                const storedOrganizationId = window.localStorage.getItem('active_organization_id');
                if (storedOrganizationId) {
                    searchOrganizationId = storedOrganizationId;

                    // Else parse the keycloak token
                } else if (keycloak.tokenParsed.api_organization_ids.length >= 1) {
                    searchOrganizationId = keycloak.tokenParsed.api_organization_ids[0]
                }

                // If there was an organization id in either localstorage or keycloak
                if (searchOrganizationId) {
                    let org = organizations.find(org => org.uuid === searchOrganizationId);
                    if (org) {
                        return dispatch('setActiveOrganization', org);
                    }

                    return apiClient.get('/organizations', { params: { uuid: searchOrganizationId } })
                        .then(response => {
                            let directResults = response.data['hydra:member'];
                            if (directResults.length >= 1) {
                                return dispatch('setActiveOrganization', organizations[0])
                            } else {
                                if (organizations.length >= 0) {
                                    return dispatch('setActiveOrganization', organizations[0])
                                }
                            }
                        })
                }

                if (organizations.length >= 0) {
                    return dispatch('setActiveOrganization', organizations[0])
                }
            }
        });
    },
    setActiveOrganization({ commit }, organization) {
        console.log('%cauth %cset active organization to %c' + organization.name + ' %c(' + organization.uuid + ')', 'color: #fd7e14', 'color: #6c757d', 'color: #0abb87', 'color: #6c757d');
        commit('SET_ACTIVE_ORGANIZATION', organization);
    },
    logout() {
        let redirectUri = window.location.href;
        keycloak.logout(redirectUri);
    }
};

// mutations
const mutations = {
    SET_USER_PROFILE(state, profile) {
        state.userProfile = profile;
        state.isLoggedIn = true;
    },
    SET_ORGANIZATIONS(state, organizations) {
        state.organizations = organizations
    },
    SET_ACTIVE_ORGANIZATION(state, organization) {
        window.localStorage.setItem('active_organization_id', organization.uuid);
        state.activeOrganization = organization
    },
    SET_ORGANIZATION_COUNT(state, count) {
        state.organizationCount = count;
    },
    SET_ROLES(state, { apiRoles }) {
        state.apiRoles = apiRoles
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
