<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
                mdi-translate
            </v-icon>
        </template>
        <v-list>
            <v-list-item-group
                v-model="$i18n.locale"
            >
                <v-list-item
                    v-for="(lang, index) in langs"
                    :key="index"
                    :value="lang.val"
                    :disabled="lang.disabled"
                >
                    <v-list-item-title>{{ lang.text }}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    data: () => ({
        langs: [
            {text: "English", val: 'en', disabled: false},
            {text: "Deutsch", val: 'de', disabled: true},
            {text: "Nederlands", val: 'nl', disabled: true}
        ]
    }),
}
</script>