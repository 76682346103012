export default function createDefaultConfig(features) {
    let defaultConfig = Object()

    for(const [featureName, feature] of Object.entries(features)) {
        defaultConfig[featureName] = Object()
        if(feature.property_groups) {
            for(const [propertyGroupName, propertyGroup] of Object.entries(feature.property_groups)) {
                if(feature[propertyGroupName] == null) { 
                    defaultConfig[featureName][propertyGroupName] = Object()
                }
                propertyGroup.byte_groups.forEach(byteGroup => {
                    for(const [propertyName, property] of Object.entries(byteGroup.properties)) {
                        defaultConfig[featureName][propertyGroupName][propertyName] = property.default
                    }
                })
            }
        }
        if(feature.byte_groups) {
            feature.byte_groups.forEach(byteGroup => {
                for(const [propertyName, property] of Object.entries(byteGroup.properties)) {
                    defaultConfig[featureName][propertyName] = property.default
                }
            })
        }
    }
    return defaultConfig
}