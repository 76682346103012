import axios from 'axios';

import Vue from 'vue';
import keycloak from '../plugins/keycloak'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

apiClient.interceptors.request.use(function (config) {
  return keycloak.updateToken(10).then((refreshed) => {
    if (refreshed) {
      console.log('%capi client %crefreshed token', 'color: #6f42c1', 'color: #6c757d');
    }
    config.headers.Authorization = 'Bearer ' + keycloak.token;
    return config;
  })
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
});

Vue.prototype.$apiClient = apiClient;

export default apiClient;
