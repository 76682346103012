import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
          customProperties: true
        },
        themes: {
          light: {
            primary: '#5867dd',
            secondary: '#f5f8fa',
            accent: '#82B1FF',
            header: '#ffffff',
            navbar: '#1f1e2c',
            background: '#f5f8fa',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107'
          },
          dark: {
            primary: '#5867dd',
            secondary: '#323248',
            accent: '#82B1FF',
            header: '#1e1e1e',
            navbar: '#1f1e2c',
            background: '#151521',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
          }
        }
    }
});
