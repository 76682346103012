import i18n from '@/i18n'

// Translates text objects from the config spec in accordance with the selected language
export default function translate(text) {
    let translation = text[i18n.locale]
    if(!translation) {  // Resorts to fallback language (English) when no translation was found
      translation = text[i18n.fallbackLocale]
      // console.warn(`No translation for '${translation}' in locale '${i18n.locale}'. Resorting to fallback locale ('${i18n.fallbackLocale}').`)
    }
    return translation
}