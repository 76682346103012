import Vue from 'vue'
import Router from 'vue-router'
import keycloak from "@/plugins/keycloak";
import store from '@/store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'editor',
            component: ()=> import('@/views/Editor.vue'),
            meta: {
                noAuthRequired: true,
                refreshEditorOnNavigation: true
            }
        },
        {
            path: '/menu',
            name: 'menu',
            component: ()=> import('@/views/Menu.vue')
        },
        {
            path: '/store',
            name: 'store',
            component: () => import('@/views/Store.vue')
        },
        {
            path: '/store-editor',
            name: 'store-editor',
            component: () => import('@/views/StoreEditor.vue'),
            meta: {
                refreshEditorOnNavigation: true
            }
        },
        {
            path: '/template/:template_id',
            name: 'template',
            component: ()=> import('@/views/Editor.vue'),
            meta: {
                refreshEditorOnNavigation: true
            }
        },
        {
            path: '/console',
            name: 'console',
            //  eslint-disable-next-line 
            beforeEnter(to, from, next) {
                window.location.href = "https://console-v2.iotracker.io/device-configuration-templates";
            },
            meta: {
                // TODO: don't use 'no' as a prefix in variables
                noAuthRequired: true
            }
        }
    ]

})

router.beforeEach((to, from, next) => {
    // Refresh editor on page navigation
    if(to.matched.some(route => route.meta.refreshEditorOnNavigation)) {
        store.state.user.initializePromise.then(() => {
            store.dispatch("initializeEditor")
        })
    }
    
    // Checks if route to visit doesn't require authentication
    if(!to.matched.some(route => route.meta.noAuthRequired)) {
        store.state.user.initializePromise.then(() => {
            if(keycloak.authenticated) {
                next()
            } 
            else {
                // TODO: Optimize this such that it does not redirect you when refreshing a page
                // try {
                    const loginUrl = keycloak.createLoginUrl()
                    window.location.replace(loginUrl)
                // }
                // catch {
                //     router.push('/')
                // }
            }
        })
        
    } 
    else {
        next()
    }
})

export default router