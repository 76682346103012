import Vue from 'vue'
import { getProperty, setProperty, deleteProperty } from 'dot-prop';
import { cloneDeep } from 'lodash'

const state = {
    template: null,
    storeEditorSelectedProperties: {},
    showTemplateFeaturesEditor: false,
    templates: [],
}

const mutations = {
    SET_TEMPLATES(state, templates) {
        state.templates = templates
    },
    SET_TEMPLATE(state, template) {
        state.template = template
    },
    SHOW_TEMPLATE_FEATURES_EDITOR(state, show) {
        state.showTemplateFeaturesEditor = show
    },
    SET_STORE_EDITOR_SELECTED_PROPERTIES(state, selectedProperties) {
        Vue.set(state, 'storeEditorSelectedProperties', selectedProperties)
    },
}

const actions = {
    setTemplates({commit}, templates) {
        commit("SET_TEMPLATES", templates)
    },
    setTemplate({commit}, template) {
        commit("SET_TEMPLATE", template)
    },
    showTemplateFeaturesEditor({commit}, show) {
        commit("SHOW_TEMPLATE_FEATURES_EDITOR", show)
    },
    toggleSelectedProperty({commit, state}, {featureName, propertyGroupName, propertyName}) {
        let selectedPropertiesClone = cloneDeep(state.storeEditorSelectedProperties)
        let origin = propertyGroupName ? `${featureName}.${propertyGroupName}` : featureName
        let currentSelectedProperties = getProperty(selectedPropertiesClone, `${origin}`)

        // If no object of properties exists yet, create one
        if(!currentSelectedProperties) {
            setProperty(selectedPropertiesClone, `${origin}`, {})
            currentSelectedProperties = getProperty(selectedPropertiesClone, `${origin}`)
        }

        // If the property name does not yet appear in the object, add it with value null
        if(!Object.keys(currentSelectedProperties).includes(propertyName)){
            currentSelectedProperties[propertyName] = null
        }
        // If the property name does appear in the feature/property group, delete it
        else {
            delete currentSelectedProperties[propertyName]
            
            if(Object.keys(currentSelectedProperties).length == 0) {
            // Delete feature/property group if there are no properties left
            deleteProperty(selectedPropertiesClone, origin)

            // Delete feature if all property groups have been deleted
            if(propertyGroupName && Object.keys(selectedPropertiesClone[featureName]).length == 0) {
                delete selectedPropertiesClone[featureName]
            }
            }
        }
        commit("SET_STORE_EDITOR_SELECTED_PROPERTIES", selectedPropertiesClone)      
    },
    
}

const getters = {
    template: (state) => {
        return state.template
    },
    showTemplateFeaturesEditor: (state) => {
        return state.showTemplateFeaturesEditor
    },
    templates: (state) => {
        return state.templates
    },
    storeEditorPropertyIsSelected: (state) => (featureName, propertyGroupName, propertyName) => {
        let properties = propertyGroupName ? getProperty(state, `storeEditorSelectedProperties.${featureName}.${propertyGroupName}`) : getProperty(state, `storeEditorSelectedProperties.${featureName}`)
        // If array with properties doesn't exist yet, always return false
        if(!properties) {
          return false
        }
        return Object.keys(properties).includes(propertyName)
      },
    storeEditorConfig: (state, getters, rootState,) => {
        for(const [featureName, featureOrPropertyGroup] of Object.entries(state.storeEditorSelectedProperties)) {
            for(const [propertyGroupNameOrPropertyName, propertyGroupOrProperty] of Object.entries(featureOrPropertyGroup)) {
                if(propertyGroupOrProperty == null || typeof propertyGroupOrProperty != 'object') {
                    let currentPropertyValue = getProperty(rootState.app.config.desired, `${featureName}.${propertyGroupNameOrPropertyName}`) ?? getProperty(rootState.app.config.actual, `${featureName}.${propertyGroupNameOrPropertyName}`)
                    setProperty(state.storeEditorSelectedProperties, `${featureName}.${propertyGroupNameOrPropertyName}`, currentPropertyValue)
                }
                else {
                    Object.keys(propertyGroupOrProperty).forEach(propertyName => {
                        let currentPropertyValue = getProperty(rootState.app.config.desired, `${featureName}.${propertyGroupNameOrPropertyName}.${propertyName}`) ?? getProperty(rootState.app.config.actual, `${featureName}.${propertyGroupNameOrPropertyName}.${propertyName}`)
                        setProperty(state.storeEditorSelectedProperties, `${featureName}.${propertyGroupNameOrPropertyName}.${propertyName}`, currentPropertyValue)
                    })
                }
            }
        }
        return state.storeEditorSelectedProperties
    },
    storeCategories: (state, getters, rootState, rootGetters) => { 
        return getProperty(rootGetters, 'spec.storeCategories')
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}