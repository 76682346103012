<template>
    <v-navigation-drawer
        app
        permanent
        mini-variant
        expand-on-hover
        clipped
      >
        <v-list
          nav
          dense
        >
            <v-list-item href="/menu">
                <v-list-item-icon>
                <v-icon>mdi-format-list-bulleted</v-icon>
                </v-list-item-icon>
                <v-list-item-title>My configurations</v-list-item-title>
            </v-list-item>
            <v-list-item href="/store">
                <v-list-item-icon>
                <v-icon>mdi-store</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    <v-icon color="primary">mdi-new-box</v-icon>
                    Template store
                </v-list-item-title>
            </v-list-item>
            <v-list-item 
                href="/store-editor"
                v-if="isAdmin"
            >
                <v-list-item-icon>
                <v-icon>mdi-store-edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Template store editor</v-list-item-title>
            </v-list-item>
            <v-divider class="my-3"/>
            <v-list-item href="/">
                <v-list-item-icon>
                <v-icon>mdi-tune</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Playground</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    computed: {
        isAdmin() {
            return this.$store.getters.isAdmin
        }
    }
}
</script>