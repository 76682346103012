import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/index.js'
import VueRouter from 'vue-router'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import i18n from './i18n'
import store from './store'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import translate from '@/utils/translate.js'

Vue.use(VueAxios, axios)
Vue.use(VueRouter)
Vue.use(Vuex)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.mixin({
  methods: {
    translate(text) {
      return translate(text)
    },
    isMobileSize() {
      return this.$vuetify.breakpoint.smAndDown
    },
  }
})
